<template>
    <div ref="elementRef">
        <slot />
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';

const elementRef = ref()
const $emit = defineEmits(['change'])
const $props = defineProps({
    threshold: {
        type: [Number, Array],
    }
})

let observer
onMounted(() => {
    const options = {
        threshold: $props.threshold
    };
    observer = new IntersectionObserver(callback, options);
    function callback(entries) {
        entries.forEach(entry => {
            $emit('change', entry.isIntersecting)
        });
    }

    const element = elementRef.value
    observer.observe(element);
})

onBeforeUnmount(() => {
    const element = elementRef.value
    if (observer && element) {
        observer.unobserve(element);
    }
})

</script>

<style lang="scss" scoped></style>