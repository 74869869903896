<template>
    <div class="table-of-content hidden md:flex flex-col sticky top-6 flex-shrink-0" :class="{ 'collapsed': collapsed }">
        <header class="header h-14 sticky top-0 bg-white flex items-center justify-between">
            <div class="content flex items-center pl-4">
                <h3>Table of Contents</h3>
            </div>
            <div class="flex justify-end w-full">
                <button
                    class="border relative z-5  focus:outline-none border-gray-200 w-10 h-10  rounded-md inline-grid place-items-center"
                    @click="toggleCollapsed">
                    <template v-if="collapsed">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M17 10C17 10.4142 16.6642 10.75 16.25 10.75L5.61208 10.75L9.76983 14.7094C10.0684 14.9965 10.0777 15.4713 9.79062 15.7698C9.50353 16.0684 9.02875 16.0777 8.73017 15.7906L3.23017 10.5406C3.08311 10.3992 3 10.204 3 10C3 9.79599 3.08311 9.60078 3.23017 9.45938L8.73017 4.20938C9.02875 3.92228 9.50353 3.93159 9.79062 4.23017C10.0777 4.52875 10.0684 5.00353 9.76983 5.29063L5.61208 9.25L16.25 9.25C16.6642 9.25 17 9.58579 17 10Z"
                                fill="#EA4E4B" />
                        </svg>
                    </template>
                    <template v-else>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M3 10C3 9.58579 3.33579 9.25 3.75 9.25L14.3879 9.25L10.2302 5.29062C9.93159 5.00353 9.92228 4.52875 10.2094 4.23017C10.4965 3.93159 10.9713 3.92228 11.2698 4.20937L16.7698 9.45937C16.9169 9.60078 17 9.79599 17 10C17 10.204 16.9169 10.3992 16.7698 10.5406L11.2698 15.7906C10.9713 16.0777 10.4965 16.0684 10.2094 15.7698C9.92228 15.4713 9.93159 14.9965 10.2302 14.7094L14.3879 10.75L3.75 10.75C3.33579 10.75 3 10.4142 3 10Z"
                                fill="#EA4E4B" />
                        </svg>
                    </template>
                </button>
            </div>
        </header>

        <div class="body  flex-1 relative pb-20">
            <div class="content">

                <div class="h-full overflow-auto p-4">
                    <ul class="list">
                        <li :class="[activeIndex == index && 'font-bold']" v-for="item, index of list"
                            :key="`table-of-content-item-${index}`" @click="$emit('click', index)">
                            <span>
                                {{ index + 1 }}.
                            </span>
                            {{ item }}
                        </li>
                    </ul>

                </div>
            </div>

        </div>

        <div class="footer bottom-0 bg-white px-4">
            <div class="footer-inner py-4">
                <div class="flex justify-center w-full">
                    <button v-if="showBackToTopBtn" @click="backToTop" class="back-to-top-btn">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M10 17C9.58579 17 9.25 16.6642 9.25 16.25L9.25 5.61208L5.29062 9.76983C5.00353 10.0684 4.52875 10.0777 4.23017 9.79063C3.93159 9.50353 3.92228 9.02875 4.20937 8.73017L9.45937 3.23017C9.60078 3.08311 9.79598 3 10 3C10.204 3 10.3992 3.08311 10.5406 3.23017L15.7906 8.73017C16.0777 9.02875 16.0684 9.50353 15.7698 9.79062C15.4713 10.0777 14.9965 10.0684 14.7094 9.76983L10.75 5.61208L10.75 16.25C10.75 16.6642 10.4142 17 10 17Z"
                                fill="#11453B" />
                        </svg>
                        <div v-if="!collapsed">
                            Back to top
                        </div>
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";


defineProps({
    list: Array,
    activeIndex: Number,
    showBackToTopBtn: Boolean,
})

const $emit = defineEmits(['click', 'back-to-top'])

const collapsed = ref(false)

const toggleCollapsed = () => {
    collapsed.value = !collapsed.value;
}

const backToTop = () => {
    $emit('back-to-top')
}

</script>

<style lang="scss" scoped>
.table-of-content {
    background: white;
    border-radius: 0px 30px 30px 30px;
    box-shadow: 0px 9px 18px rgba(170, 170, 170, 0.15);
    min-height: 80vh;
    overflow: auto;
    max-width: 250px;
    width: 100%;
    overflow: hidden;
    transition: 0.25s ease;
    /* transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); */

    .content {
        transform: translateX(0);
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 250px;
        transition: .3s ease;
        /* transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); */
    }

    &.collapsed {
        max-width: 72px;
        min-height: 50vh;

        .content {
            transform: translateX(120%);
            opacity: 0;
        }
    }

    .header {
        padding: 10px 16px;
        border-bottom: 5px solid #F2F1F1;
        height: 70px
    }

    h3 {
        font-size: 18px;
        color: #292A29;
        font-weight: 600;
    }

    .list {
        display: grid;
        gap: 14px;
        list-style: none;
        font-weight: 500;
        font-size: 13px;

        li {
            cursor: pointer;
            display: flex;
            align-items: flex-start;
            gap: 4px;
            text-transform: uppercase;

            &:hover {
                color: #EA4E4B;
            }
        }
    }

    .body {
        padding: 18px;
    }

    .footer-inner {
        border-top: 1px solid #E7E8E7;
    }

    .back-to-top-btn {
        color: #11453B;
        display: inline-flex;
        align-items: center;
        gap: 5px;
    }
}
</style>