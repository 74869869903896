<template>
    <div class="document-section px-5 pb-8">
        <h3 class="text-xl md:text-2xl font-bold py-5 sticky top-0 md:static bg-white">
            {{ heading }}</h3>
        <slot />
    </div>
</template>

<script setup>
defineProps({
    heading: String,
    highlight: Boolean,
})
</script>

<script>
export default {
    name: 'DocumentSection'
}
</script>

<style lang="scss">
.document-section {

    p {
        margin-bottom: 16px;
    }

    ul {
        list-style: disc;
        padding-left: 30px;
        display: grid;
        gap: 15px;
        margin-bottom: 16px;
    }

    >*:last-child {
        margin-bottom: 0;
    }

}
</style>