<template>
    <div class="document relative flex items-start gap-4">
        <div class="document-content">
            <header class="px-6 py-4">
                <Inview @change="toggleBackToTopBtn">
                    <h3 class="text-2xl font-bold">{{ title }}</h3>
                </Inview>
            </header>
            <div class="document-section-list">
                <div class="document-section-list-item" :data-section-index="index" style="padding-top: 2px;"
                    v-for="child, index of  sections " :key="index">
                    <component :is="child" :highlight="highlightedSection === index" :heading="getSectionHeading(index)" />
                </div>
            </div>
        </div>

        <TableOfContent :list="headings" :activeIndex="activeSection" @click="jumpToSection" @back-to-top="backToTop"
            :showBackToTopBtn="showBackToTopBtn" />


        <div v-if="showBackToTopBtn" class="block md:hidden fixed bottom-0 right-0 z-10 p-4">
            <button class="tab-btn" @click="backToTop">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10 17C9.58579 17 9.25 16.6642 9.25 16.25L9.25 5.61208L5.29062 9.76983C5.00353 10.0684 4.52875 10.0777 4.23017 9.79063C3.93159 9.50353 3.92228 9.02875 4.20937 8.73017L9.45937 3.23017C9.60078 3.08311 9.79598 3 10 3C10.204 3 10.3992 3.08311 10.5406 3.23017L15.7906 8.73017C16.0777 9.02875 16.0684 9.50353 15.7698 9.79062C15.4713 10.0777 14.9965 10.0684 14.7094 9.76983L10.75 5.61208L10.75 16.25C10.75 16.6642 10.4142 17 10 17Z"
                        fill="#EA4E4B" />
                </svg>
                Back to Top
            </button>
        </div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, useSlots } from 'vue';
import TableOfContent from "./TableOfContent.vue"
import Inview from '../Inview.vue';

const $props = defineProps({
    title: String,
    numbered: Boolean
})

const $slots = useSlots()

const highlightedSection = ref()
const activeSection = ref()
const showBackToTopBtn = ref(false)

const toggleBackToTopBtn = (inview) => {
    if (inview) {
        showBackToTopBtn.value = false
    } else {
        showBackToTopBtn.value = true
    }
}

const getSections = () => {
    const children = $slots.default()
    return children.reduce((items, child) => {
        if (child.type.name === 'DocumentSection') {
            items.push(child)
        }
        return items
    }, [])
}

const sections = getSections()

const headings = sections.map(section => section.props && section.props.heading)

const highlightSection = (index) => {
    highlightedSection.value = index
    setTimeout(() => {
        highlightedSection.value = undefined
    }, 100);
}

const getSectionHeading = (index) => {
    let heading = headings[index]
    if ($props.numbered) {
        return `${String(index + 1).padStart(2, '0')}. ${heading}`
    }
    return heading
}

const jumpToSection = (index) => {
    // highlightSection(index)
    const selector = `[data-section-index="${index}"]`
    const section = document.querySelector(selector)
    if (section) {
        // scroll to child 
        const content = section.querySelector('div')
        content.scrollIntoView({
            behavior: 'smooth'
        })
    }
}

const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

let observer


onMounted(() => {
    const options = {
        threshold: [0, 0.5, 1],
    };
    observer = new IntersectionObserver(callback, options);

    function callback(entries) {
        let activeSectionIndex;
        let activeSectionTop
        entries.forEach(entry => {
            const { target, boundingClientRect, isIntersecting } = entry
            const index = Number(target.getAttribute('data-section-index'))
            const boundingRectTop = boundingClientRect.y
            if (isIntersecting && boundingRectTop <= 0) {
                if (activeSectionIndex !== undefined) {
                    if (boundingRectTop > activeSectionTop) {
                        activeSectionTop = boundingRectTop
                        activeSectionIndex = index
                    }
                } else {
                    activeSectionIndex = index
                    activeSectionTop = boundingRectTop

                }
            }
        });
        if (activeSectionIndex !== undefined) {
            activeSection.value = activeSectionIndex
        }
    }


    const sections = document.querySelectorAll('.document-section-list-item')

    sections.forEach(section => {
        observer.observe(section)
    })

})

onBeforeUnmount(() => {
    if (observer) {
        const sections = document.querySelectorAll('.document-section-list-item')
        sections.forEach(section => {
            observer.unobserve(section)
        })
    }
})

</script>

<style lang="css" scoped>
.document-content {
    box-shadow: 0px 9px 18px rgba(170, 170, 170, 0.15);
    border-radius: 4px;
    border-radius: 30px 0 30px 30px;
    background-color: white;
}

.document-section-list-item {
    position: relative;
    border-top: 5px solid #f2f1f1;
}

.tab-btn {
    display: flex;
    gap: 8px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 9px 18px rgba(170, 170, 170, 0.15);
    border-radius: 40px 40px 40px 0px;
    color: #ea4e4b;
    height: 60px;
    padding: 0 20px;
}
</style>