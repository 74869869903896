<template>
  <nav class="site-nav bg-transparent py-5 md:py-6">
    <div class="l-container flex items-center justify-between">
      <RouterLink to="/" class="hidden nav-logo md:inline-block">
        <Logo />
      </RouterLink>
      <RouterLink to="/" class="nav-logo inline-block md:hidden">
        <MobileLogo />
      </RouterLink>
      <!-- <ul class="hidden lg:flex items-center gap-6">
        <li class="relative" @click="$emit('scroll', 'footer')">
          <div class="nav-link" to="/#">Contact Us</div>
        </li>
        <li class="relative" @click="$emit('scroll', 'faq')">
          <div class="nav-link" to="/#">FAQs</div>
        </li>
      </ul> -->

      <div class="nav-btns">
        <SiteButton
          color=" text-secondary bg-white border border-secondary"
          @click="$emit('handleGet')"
        >
          <span class="hidden lg:block"> Get WorknProsper </span>
          <span class="block lg:hidden"> Download app </span>
        </SiteButton>

        <!-- <RouterLink to="/register" class="register-btn btn-shadow"
              >Register</RouterLink
            > -->
      </div>
    </div>
  </nav>
</template>

<script setup>
import MegaMenu from "./MegaMenu.vue";

import individualIcon from "./assets/individual.svg";
import individualActive from "./assets/individual-active.svg";
import hrIcon from "./assets/hr.svg";
import hrActive from "./assets/hr-active.svg";
import businessIcon from "./assets/business.svg";
import businessActive from "./assets/business-active.svg";
import payeIcon from "./assets/paye.svg";
import payeActive from "./assets/paye-active.svg";
import assessmentIcon from "./assets/assessment.svg";
import assessmentActive from "./assets/assessment-active.svg";
import deviceIcon from "./assets/device.svg";
import wnpIcon from "./assets/wnp.svg";
import wnpActive from "./assets/wnpActive.svg";
import deviceActive from "./assets/device-active.svg";
import overviewIcon from "./assets/overview.svg";
import overviewActive from "./assets/overview-active.svg";
import apiDocumentation from "./assets/apiDocumentation.svg";
import apiDocumentationActive from "./assets/apiDocumentationActive.svg";
import background from "./assets/mobileBackground.png";
import SiteButton from "./SiteButton.vue";

import Logo from "./WnpLogo.vue";
import MobileLogo from "./WnpMobileLogo.vue";

import CloseIcon from "./icons/Close.vue";
const hoveringItem = ref(null);

import { computed, ref } from "vue";

const show = ref(false);
const isHovered = ref(false);

const showDiv = () => {
  isHovered.value = true;
};

const ProductMenuItems = computed(() => {
  return [
    {
      title: "Individuals",
      description:
        "Add your service providers, personal assistants, chauffeurs, chefs, and more to your Eazipay and pay all in one click!",
      routes: "/individual",
      routeName: null,
      icon: individualIcon,
      iconActive: individualActive,
    },
    {
      title: "Business",
      description: `Add your employee the simple way you know and run their payroll.All in seconds!`,
      routes: "/business",
      routeName: null,
      icon: businessIcon,
      iconActive: businessActive,
    },
    {
      title: "HR Store",
      description:
        "The all-inclusive platform to buy or rent company assets and employee perks, curated to ensure the happiness of your staff",
      routes: "/hr-stores",
      routeName: null,
      icon: hrIcon,
      iconActive: hrActive,
    },

    {
      title: "Annual PAYE Filing",
      description: `Beat the deadline and save N500,000 in tax penalties.`,
      routes: "/paye",
      routeName: null,
      icon: payeIcon,
      iconActive: payeActive,
    },
    {
      title: "Assessment",
      description: `Use our world-class online exam management tool with AI-enabled proctoring tool for all your recruitment needs and career interests or job role tests`,
      routes: "/assess-employee",
      routeName: null,
      icon: assessmentIcon,
      iconActive: assessmentActive,
    },
    {
      title: "Device Management",
      description: `Configure and secure employees’ gadgets and devices from one dashboard`,
      routes: "/manage-employee",
      routeName: null,
      icon: deviceIcon,
      iconActive: deviceActive,
    },
  ];
});

const EmployeeMenuItems = computed(() => {
  return [
    {
      title: "WorknProsper",
      description: `Work Smarter, Prosper Faster, and Achieve Financial Freedom With WorknProsper.`,
      routes: "/wnpLanding",
      routeName: null,
      icon: wnpIcon,
      iconActive: wnpActive,
    },
  ];
});
const DeveloperMenuItems = computed(() => {
  return [
    {
      title: "Overview",
      routes: "/developers",
      routeName: null,
      icon: overviewIcon,
      iconActive: overviewActive,
    },
    {
      title: "API Documentation",
      description: ``,
      routes: "/developers/documentation",
      routeName: null,
      icon: apiDocumentation,
      iconActive: apiDocumentationActive,
    },
    {
      title: "API Status",
      description: ``,
      routes: "/#",
      routeName: null,
      icon: apiDocumentation,
      iconActive: apiDocumentationActive,
    },
  ];
});
</script>

<style lang="scss" scoped>
.over {
  box-shadow: 0px 20px 48px 0px #aaaaaa4a;
}
.hamburger {
  display: grid;
  gap: 4px;
  cursor: pointer;

  span {
    display: inline-block;
    height: 2px;
    width: 20px;
    background-color: #14181f;
    border-radius: 2px;
  }
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 35px;
}

.nav-link {
  font-weight: 500;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    color: #ea4e4b;
  }

  &.router-link-active {
    color: #ea4e4b;
  }
}

.nav-btns {
  display: flex;
  align-items: center;
  gap: 20px;

  a {
    width: auto;
    border-radius: 99px;
    padding: 10px 24px 10px 24px;
    height: 40px;
    border: 1px solid #ea4e4b;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .login-btn {
    // border: 1px solid #11453b;
    background: white;
    color: #ea4e4b;
  }
}

.mobile-nav-menu {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: 20;
  width: 95vw;
  transform: translateX(100%);
  transition: 0.24s ease;
  visibility: hidden;

  &.open {
    transform: translateX(0);
    visibility: visible;
  }

  a {
    color: #515251;
    font-size: 16px;
    font-weight: bold;
  }

  a.btn-shadow {
    color: white;
  }
}

.mobile-nav-overlay {
  background: rgb(17, 69, 59, 0.5);
  opacity: 0;
  visibility: hidden;
  opacity: 0.25s ease;
  z-index: 10;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
</style>
