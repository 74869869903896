<template>
  <div class="modal-overlay">
    <div class="modal">
      <div class="modal-content">
        <div class="cursor-pointer flex justify-center text-center" @click="closeModal">
          <img src="../assets/closeicon.svg" class="w-10 h-10" />
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["close"]);
const closeModal = () => {
  emit("close");
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #292a29;
  padding: 64px 128px 64px 128px;
  border-radius: 112px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-close-button {
  margin-top: 10px;
}
</style>
